/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


googletag.cmd.push(() => {
  // Full-page banner
  const mapping1 = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // The above-the-header banner (LB1)
  const mappingHeader = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([0, 0], [])
    .build();

  // For 160x600
  const mapping2 = googletag.sizeMapping()
    .addSize([1024, 0], [[160, 600], [300, 600]])
    .addSize([0, 0], [[336, 280], [300, 250]])
    .build();

  // For 300x250 MPU4
  const mapping3 = googletag.sizeMapping()
    .addSize([1024, 0], [])
    .addSize([0, 0], [300, 250])
    .build();

  const mapping4 = googletag.sizeMapping()
    .addSize([1220, 0], [728, 90])
    .addSize([1024, 0], [])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();


  // Adslot 1 declaration
  gptadslots[1] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[728, 90]],
    'div-gpt-ad-546180090590731748-1',
  ).setTargeting('pos', ['LB1']).defineSizeMapping(mappingHeader)
    .addService(googletag.pubads());

  // Adslot 3 declaration
  gptadslots[3] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 90]],
    'div-gpt-ad-546180090590731748-3',
  ).setTargeting('pos', ['30090-1'])
    .addService(googletag.pubads());

  // Adslot 4 declaration
  gptadslots[4] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 90]],
    'div-gpt-ad-546180090590731748-4',
  ).setTargeting('pos', ['30090-2'])
    .addService(googletag.pubads());

  // Adslot 5 declaration
  gptadslots[5] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 90]],
    'div-gpt-ad-546180090590731748-5',
  ).setTargeting('pos', ['30090-3'])
    .addService(googletag.pubads());

  // Adslot 6 declaration
  gptadslots[6] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[160, 100]],
    'div-gpt-ad-546180090590731748-6',
  ).setTargeting('pos', ['160100-1'])
    .addService(googletag.pubads());

  // Adslot 7 declaration
  gptadslots[7] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[160, 600]],
    'div-gpt-ad-546180090590731748-7',
  ).setTargeting('pos', ['WSKY1']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // A regular LB2 - a 728px ad in the content area
  gptadslots[11] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[728, 90]],
    'div-gpt-ad-546180090590731748-11',
  ).setTargeting('pos', ['LB2']).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  // Adslot 12 declaration
  gptadslots[12] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 250]],
    'div-gpt-ad-546180090590731748-12',
  ).setTargeting('pos', ['MPU3'])
    .addService(googletag.pubads());

  // Adslot 13 declaration
  gptadslots[13] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 250]],
    'div-gpt-ad-546180090590731748-13',
  ).setTargeting('pos', ['MPU2'])
    .addService(googletag.pubads());

  // Adslot 14 declaration
  gptadslots[14] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[160, 100]],
    'div-gpt-ad-546180090590731748-14',
  ).setTargeting('pos', ['160100-2'])
    .addService(googletag.pubads());

  // Adslot 18 declaration
  gptadslots[18] = googletag.defineSlot(
    '/55877742/FireRescue1_Remnant',
    [[728, 90]],
    'div-gpt-ad-546180090590731748-18',
  ).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  // Adslot 22 declaration
  gptadslots[22] = googletag.defineSlot(
    '/55877742/FireRescue1_Skin',
    [[1, 1]],
    'div-gpt-ad-546180090590731748-22',
  ).addService(googletag.pubads());

  // Adslot 25 declaration
  gptadslots[25] = googletag.defineOutOfPageSlot(
    '/55877742/FireRescue1_Adhesion',
    'div-gpt-ad-546180090590731748-25',
  ).addService(googletag.pubads());

  // Adslot 12 declaration
  gptadslots[26] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[300, 250]],
    'div-gpt-ad-546180090590731748-26',
  ).setTargeting('pos', ['MPU4']).defineSizeMapping(mapping3)
    .addService(googletag.pubads());

  gptadslots[27] = googletag.defineSlot(
    '/55877742/FireRescue1_Sponsored_Article',
    [[728, 90]],
    'div-gpt-ad-546180090590731748-27',
  ).setTargeting('pos', ['SponsoredLB']).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  // A sticky LB2, it spans both the content and the sidebar
  gptadslots[29] = googletag.defineSlot(
    '/55877742/FireRescue1',
    [[728, 90]],
    'div-gpt-ad-546180090590731748-29',
  ).setTargeting('Pos', ['LB2']).defineSizeMapping(mapping1)
    .addService(googletag.pubads());

  dfpManager.setTargets(googletag);
  dfpManager.enable();
});
